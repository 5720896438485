import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './style.css'
import Preloader from './features/Preloader';

const App = lazy(() => import('./App'))

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <Suspense fallback={<Preloader/>}>
            <App />
        </Suspense>
    </React.StrictMode>
)

