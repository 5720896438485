/* eslint-disable jsx-a11y/alt-text */
import Loader from '../../shared/Loader'

export default function Preloader() {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#22272B',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '2rem'
        }}>
            <img src={'/images/traktor.png'} style={{
                width: '15rem'
            }}/>
            <Loader/>
        </div>
    )
}
