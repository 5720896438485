import './style.css'

type sizeT = 's' | 'm'

export default function Loader({ size = 'm' }: { size?: sizeT }) {
    return (
        <>
            <style>{`
                .loader:before {
                    left: ${size === 's' ? '17px' : '34px'};
                    bottom: ${size === 's' ? '4px' : '8px'};
                    width: ${size === 's' ? '15px' : '30px'};
                    height: ${size === 's' ? '15px' : '30px'};
                }
            `}</style>
            <span className="loader"
            style={{
                fontSize: getSize(size)
            }}>L &nbsp; ading</span>
        </>
    )   
}

function getSize(size: sizeT) {
    switch (size) {
        case 's': return '24px'
        case 'm': return '48px'
    }
}